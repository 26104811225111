import { Center, Loader, Paper, Text, Title } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { useResumesQuery } from '../../queries/ResumeQueries';

import classes from './HomeCard.module.css';

const ApplicationStatusCard: React.FC = () => {
  const { t } = useTranslation();
  const { data: resumes } = useResumesQuery();
  const navigate = useNavigate();

  return (
    <Paper
      shadow="lg"
      radius="xl"
      p="xl"
      className={classes.homeCard}
      onClick={() => {
        navigate(AppRouteURL.resumes.list);
      }}
    >
      <div className={classes.homeCardWrapper}>
        <Title order={5}>{t('header.applications')}</Title>

        <div className={classes.homeCardContent}>
          <Center h="100%">
            {resumes ? (
              <Text size="4rem" ta="center">
                {resumes.length}
              </Text>
            ) : (
              <Loader />
            )}
          </Center>
        </div>
      </div>
    </Paper>
  );
};

export default ApplicationStatusCard;
