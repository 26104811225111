import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { AppRouteURL } from '../AppRouteURL';
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen';
import { SignInWithLinkedInCallback } from '../components/SignInWithLinkedInButton/SignInWithLinkedInCallback';
import { IUser } from '../models/User';
import AboutPage from '../pages/AboutPage/AboutPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import LegalDocumentPage from '../pages/LegalDocumentPage/LegalDocumentPage';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { ResetPasswordPage } from '../pages/ResetPasswordPage/ResetPasswordPage';
import { SignupPage } from '../pages/SignupPage/SignupPage';
import { VerifyEmailPage } from '../pages/VerifyEmailPage';

const getAnonRoutes = (loading: boolean, user?: IUser | null) =>
  createRoutesFromElements(
    <>
      <Route
        path={AppRouteURL.login}
        element={
          user ? (
            <Navigate to={AppRouteURL.home} replace />
          ) : loading ? (
            <LoadingScreen />
          ) : (
            <LoginPage />
          )
        }
      />
      <Route
        path={AppRouteURL.signup}
        element={
          user ? (
            <Navigate to={AppRouteURL.home} replace />
          ) : loading ? (
            <LoadingScreen />
          ) : (
            <SignupPage />
          )
        }
      />
      <Route
        path={AppRouteURL.linkedInCallback}
        element={<SignInWithLinkedInCallback />}
      />
      <Route
        path={AppRouteURL.forgotPassword}
        element={<ForgotPasswordPage />}
      />
      <Route path={AppRouteURL.resetPassword} element={<ResetPasswordPage />} />
      <Route path={AppRouteURL.verifyEmail} element={<VerifyEmailPage />} />
      <Route path={AppRouteURL.about} element={<AboutPage />} />
      <Route
        path={AppRouteURL.terms}
        element={<LegalDocumentPage type="terms" />}
      />
      <Route
        path={AppRouteURL.cookiePolicy}
        element={<LegalDocumentPage type="cookie" />}
      />
      <Route
        path={AppRouteURL.privacyPolicy}
        element={<LegalDocumentPage type="privacy" />}
      />
      <Route
        path={AppRouteURL.disclaimer}
        element={<LegalDocumentPage type="disclaimer" />}
      />
      <Route
        path={AppRouteURL.acceptableUse}
        element={<LegalDocumentPage type="acceptableUse" />}
      />
    </>,
  );

export default getAnonRoutes;
