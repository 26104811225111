import { Center, Container } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { GOOGLE_CLIENT_ID } from '../../Config';
import Platform from '../../services/Platform';
import Tracking from '../../services/Tracking';

export type GoogleSignInText =
  | 'signin_with'
  | 'signup_with'
  | 'continue_with'
  | 'signin';

export const SignInWithGoogleButton: React.FC<{ text: GoogleSignInText }> = ({
  text,
}) => {
  if (!GOOGLE_CLIENT_ID) {
    return null;
  }

  const { i18n } = useTranslation();

  const href = Api.getApiRoute(ApiRoutes.SignInWithGoogleCallback);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container
      h={42}
      w={266}
      p={0}
      onClick={() => {
        if (text === 'signup_with') {
          Tracking.trackSignup({ method: 'google' }).catch(console.error);
        } else {
          Tracking.trackLogin({ method: 'google' });
        }
      }}
    >
      <Center>
        <div
          id="g_id_onload"
          data-client_id={GOOGLE_CLIENT_ID}
          data-login_uri={href}
          data-auto_prompt="false"
          data-context={text === 'signin_with' ? 'signin' : 'signup'}
          data-auto-select="true"
          data-itp_support="true"
          data-ux_mode={Platform.isPwa ? 'redirect' : 'popup'}
        ></div>
        <div
          className="g_id_signin"
          data-type="standard"
          data-size="large"
          data-theme="outline"
          data-text={text}
          data-shape="rectangular"
          data-logo_alignment="left"
          data-locale={i18n.languages[0]}
        ></div>
      </Center>
    </Container>
  );
};
