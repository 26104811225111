import { Button, Container, Stack, Title } from '@mantine/core';
import { IconArrowLeftTail } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { EditResumeForm } from '../../components/EditResumeForm/EditResumeForm';

export const EditResumePage: React.FC = () => {
  const resumeId = useParams().resumeId;

  const { t } = useTranslation();

  return (
    <Container>
      <Button
        mb="xl"
        ml={-20}
        leftSection={<IconArrowLeftTail />}
        variant="subtle"
        size="xs"
        component={Link}
        to={AppRouteURL.resumes.list}
      >
        {t('edit.backToOverview')}
      </Button>

      <Stack>
        <Bowl title={t('edit.pageTitle')} />

        <Title order={2}>{t('edit.pageTitle')}</Title>

        {resumeId && <EditResumeForm resumeId={resumeId} />}
      </Stack>
    </Container>
  );
};
