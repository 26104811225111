import { AppShell, Box, Group, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageMenu } from '../Layout/LanguageMenu';

export const LoginFooter: React.FC = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const { t } = useTranslation();

  return (
    <AppShell.Footer
      style={{
        backgroundColor: theme.colors.gray[5],
      }}
    >
      <Group mx="sm" justify="space-between">
        <Text c="white" mx="lg" my={4} size="sm">
          {t('login.copyrightFooter')}
        </Text>
        <Box mx="lg" my={4}>
          <LanguageMenu size="sm" long={!isMobile} />
        </Box>
      </Group>
    </AppShell.Footer>
  );
};
