import {
  Box,
  Button,
  Center,
  Group,
  SegmentedControl,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { IconChevronRight, IconClipboard, IconLink } from '@tabler/icons-react';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IResume, JobAdType, MAX_JOB_AD_LENGTH } from '../../models/Resume';
import {
  useCreateResumeMutation,
  useResumeQuery,
  useUpdateResumeMutation,
} from '../../queries/ResumeQueries';

const MIN_AD_LENGTH = 10;
const MAX_URL_LENGTH = 16000;

export const CreateResumeForm: React.FC<{
  resumeId?: IResume['id'];
  onComplete: (resumeId: IResume['id']) => void;
  onError: (err: unknown) => void;
}> = ({ resumeId, onComplete, onError }) => {
  const { t } = useTranslation();

  const jobAdTypeOptions: { label: React.ReactNode; value: JobAdType }[] = [
    {
      label: (
        <Center>
          <IconLink size="1rem" />
          <Box ml={10} fw={500}>
            {t('create.step1.linkToggle')}
          </Box>
        </Center>
      ),
      value: JobAdType.URL,
    },
    {
      label: (
        <Center>
          <IconClipboard size="1rem" />
          <Box ml={10} fw={500}>
            {t('create.step1.textToggle')}
          </Box>
        </Center>
      ),
      value: JobAdType.Text,
    },
  ];

  const CreateResumeSchema = useMemo(
    () =>
      Yup.object().shape({
        jobAdUrl: Yup.string()
          .url(t('errors.invalidUrl'))
          .matches(
            /^(?!https?:\/\/(www\.)?linkedin\.com\/)https?:\/\/\S+/,
            t('create.step1.linkedInNotSupported'),
          )
          .max(MAX_URL_LENGTH, t('errors.maxLength', { max: MAX_URL_LENGTH })),
        ad: Yup.string()
          .min(MIN_AD_LENGTH, t('errors.minLength', { min: MIN_AD_LENGTH }))
          .max(
            MAX_JOB_AD_LENGTH,
            t('errors.maxLength', { max: MAX_JOB_AD_LENGTH }),
          ),
      }),
    [t],
  );

  const resumeMutation = resumeId
    ? useUpdateResumeMutation(resumeId)
    : useCreateResumeMutation();

  const { data: resume } = useResumeQuery(resumeId);

  const formik = useFormik({
    initialValues: {
      jobAdType: resume?.jobAdType || JobAdType.URL,
      jobAdUrl: resume?.jobAdUrl || '',
      ad: resume?.ad || '',
    },
    validationSchema: CreateResumeSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        const resume = await resumeMutation.mutateAsync(values);

        onComplete(resume.id);
      } catch (error) {
        actions.setStatus(error);
        onError(error);
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack gap="xl" mb="xl">
          <Stack gap="xl" align="center">
            <Title order={3}>{t('create.step1.pageTitle')}</Title>
            <Stack>
              <Text ta="center">{t('create.step1.pageDescription1')}</Text>
            </Stack>
          </Stack>

          <Center>
            <SegmentedControl
              radius="xl"
              size="sm"
              color="blue"
              {...getFieldProps('jobAdType')}
              // value={jobAdType}
              onChange={(value) => {
                void setFieldValue('jobAdType', value as JobAdType);
              }}
              data={jobAdTypeOptions}
            />
          </Center>

          {values.jobAdType === JobAdType.URL ? (
            <TextInput
              label={t('create.step1.jobAdUrlLabel')}
              placeholder={t('create.step1.jobAdUrlPlaceholder')}
              {...getFieldProps('jobAdUrl')}
              error={touched.jobAdUrl && errors.jobAdUrl}
              leftSection={<IconLink />}
              description={t('create.step1.pageDescription2')}
            />
          ) : (
            <Box>
              <Textarea
                placeholder={t('create.step1.jobAdPlaceholder')}
                label={t('create.step1.jobAdLabel')}
                {...getFieldProps('ad')}
                minRows={12}
                autosize
                error={touched.ad && errors.ad}
              />
              <Group justify="flex-end">
                <Text size="xs" fw={100} mr={4}>
                  {values.ad.length}/{MAX_JOB_AD_LENGTH}
                </Text>
              </Group>
            </Box>
          )}

          <Group justify="flex-end">
            <Button
              type="submit"
              size="md"
              variant="filled"
              disabled={
                !formik.isValid ||
                (values.jobAdType === JobAdType.Text && !values.ad) ||
                (values.jobAdType === JobAdType.URL && !values.jobAdUrl)
              }
              loading={isSubmitting}
              rightSection={<IconChevronRight />}
            >
              {isSubmitting ? t('labels.saving') : t('labels.next')}
            </Button>
          </Group>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
