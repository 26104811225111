import { Box, Group, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WhatIsThisProps {
  message: string;
}

const WhatIsThis: React.FC<WhatIsThisProps> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Group gap={4} justify="left">
        <IconInfoCircle size={16} color="#999" />
        <Tooltip
          label={
            <Text size="sm" p="sm">
              {message}
            </Text>
          }
          position="bottom"
          withArrow
          multiline
          w={320}
          events={{ hover: true, focus: true, touch: true }}
        >
          <Text
            size="sm"
            c="dimmed"
            td="underline dotted #999"
            style={{ cursor: 'pointer' }}
          >
            {t('labels.whatIsThis')}
          </Text>
        </Tooltip>
      </Group>
    </Box>
  );
};

export default WhatIsThis;
