import { useEffect } from 'react';

export const TermlyEmbed: React.FC<{ termlyId: string }> = ({ termlyId }) => {
  const termlyDivProps = {
    name: 'termly-embed',
    'data-id': termlyId,
    'data-type': 'iframe',
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div {...termlyDivProps} />;
};
