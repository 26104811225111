import languages from '@cospired/i18n-iso-languages';
import {
  ActionIcon,
  Autocomplete,
  Box,
  Button,
  Flex,
  Select,
  Stack,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import {
  IconAlertTriangle,
  IconCirclePlus,
  IconWorld,
  IconX,
} from '@tabler/icons-react';
import { Field, FieldArray } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IUserProfile } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { ProfileModal } from './ProfileModal';

type LanguagesProfileValues = Pick<IUserProfile, 'languages'>;

const LanguagesInfoSchema = Yup.object().shape({
  languages: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Language name is required'),
      level: Yup.string().required('Proficiency level is required'),
    }),
  ),
});

export const LanguagesModal: React.FC<{
  opened: boolean;
  onClose: () => void;
}> = ({ opened, onClose }) => {
  const { t, i18n } = useTranslation();
  const { data: profile } = useProfileQuery();

  const languagesData = useMemo(() => {
    const uiLang = i18n.languages[0];
    return Object.values(languages.getNames(uiLang));
  }, []);

  const initialValues = {
    languages: profile?.languages ?? [],
  } as LanguagesProfileValues;

  const skillLevelData = [
    { value: '0', label: t('skills.basic') },
    { value: '1', label: t('skills.advanced') },
    { value: '2', label: t('skills.professional') },
    { value: '3', label: t('skills.native') },
  ] as const;

  return (
    <ProfileModal<LanguagesProfileValues>
      opened={opened}
      onClose={onClose}
      title={t('profile.languages')}
      initialValues={initialValues}
      validationSchema={LanguagesInfoSchema}
    >
      {(formikProps) => (
        <FieldArray name="languages">
          {(arrayHelpers) => (
            <Stack gap="md">
              <Stack>
                {formikProps.values.languages.map((language, index) => (
                  <Flex key={index} gap={16} align="flex-end">
                    <Box style={{ flexGrow: 2 }}>
                      <Field
                        {...formikProps.getFieldProps(
                          `languages[${index}].name`,
                        )}
                        onChange={(value: string) =>
                          formikProps.setFieldValue(
                            `languages[${index}].name`,
                            value,
                          )
                        }
                        as={Autocomplete}
                        data={languagesData || []}
                        label={t('labels.language')}
                        placeholder={t('labels.language')}
                        leftSection={<IconWorld />}
                        error={
                          formikProps.touched[
                            `languages[${index}].name` as keyof LanguagesProfileValues
                          ] &&
                          (formikProps.errors[
                            `languages[${index}].name` as keyof LanguagesProfileValues
                          ] as string)
                        }
                        rightSection={
                          formikProps.values[
                            `languages[${index}].name` as keyof LanguagesProfileValues
                          ] !==
                            formikProps.initialValues[
                              `languages[${index}].name` as keyof LanguagesProfileValues
                            ] && (
                            <Tooltip label={t('profile.unsavedChangesTooltip')}>
                              <ThemeIcon color="yellow" variant="light">
                                <IconAlertTriangle />
                              </ThemeIcon>
                            </Tooltip>
                          )
                        }
                      />
                    </Box>
                    <Field
                      as={Select}
                      label={t('labels.skillLevel')}
                      data={skillLevelData}
                      {...formikProps.getFieldProps(
                        `languages[${index}].level`,
                      )}
                      onChange={(value: string) =>
                        formikProps.setFieldValue(
                          `languages[${index}].level`,
                          value,
                        )
                      }
                      allowDeselect={false}
                    />
                    <ActionIcon
                      onClick={() => arrayHelpers.remove(index)}
                      color="red"
                      variant="subtle"
                      mb={4}
                    >
                      <IconX />
                    </ActionIcon>
                  </Flex>
                ))}
              </Stack>
              <div>
                <Button
                  size="sm"
                  mt="lg"
                  leftSection={<IconCirclePlus />}
                  onClick={() => {
                    arrayHelpers.push({ name: '', level: '0' });
                  }}
                  variant="outline"
                >
                  {t('profile.addLanguage')}
                </Button>
              </div>
            </Stack>
          )}
        </FieldArray>
      )}
    </ProfileModal>
  );
};
