import { AppShell } from '@mantine/core';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PlatformBridge } from '../PlatformBridge/PlatformBridge';
import { AppHeader } from './AppHeader';
import { AppNavbar } from './AppNavBar';

export const Layout: React.FC = () => {
  const [open, setOpen] = useState(false);
  // const { width, height } = useViewportSize();
  // const safeAreaTop = useMemo(
  //   () =>
  //     parseInt(
  //       getComputedStyle(document.documentElement).getPropertyValue('--sat'),
  //     ),
  //   [width, height],
  // );
  //

  return (
    <AppShell
      // navbar={<AppNavbar open={open} setOpen={setOpen} />}
      header={{ height: 64 }}
      padding={{ base: 'md', sm: 'sm' }}
      navbar={{
        width: { base: 200, md: 300 },
        breakpoint: 'sm',
        collapsed: { mobile: !open },
      }}
    >
      <AppHeader open={open} setOpen={setOpen} />
      <AppNavbar setOpen={setOpen} />
      <AppShell.Main>
        <PlatformBridge />
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
