import { useQuery } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { IProduct } from '../models/Product';

export type StripeProduct = IProduct;

const QUERY_KEY_PRODUCTS = ['products'];

export const useProductQuery = () =>
  useQuery({
    queryKey: QUERY_KEY_PRODUCTS,
    queryFn: () =>
      Api.ky
        .get<{ prices: StripeProduct[] }>(ApiRoutes.Prices)
        .json()
        .then((data) => data.prices),
    staleTime: 1000 * 60 * 60,
  });
