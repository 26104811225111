import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  IResume,
  ResumeLanguage,
  ResumeLanguageCode,
  ResumeStyles,
} from '../../models/Resume';
import {
  IUpdateResumeDto,
  useResumeQuery,
  useUpdateResumeMutation,
} from '../../queries/ResumeQueries';

const ReviewResumeSchema = Yup.object().shape({
  info: Yup.string()
    .optional()
    .min(3, 'Please enter at least 3 characters')
    .max(500, 'Please enter not more than 500 characters'),
  language: Yup.string().nullable().oneOf(Object.keys(ResumeLanguage)),
});

export const ReviewResumeForm: React.FC<{
  resumeId: IResume['id'];
  onComplete: () => void;
  onBack: () => void;
  onError: (err: unknown) => void;
}> = ({ resumeId, onComplete, onBack, onError }) => {
  const updateMutation = useUpdateResumeMutation(resumeId);
  const { data: resume } = useResumeQuery(resumeId);

  const { t, i18n } = useTranslation();

  const initialValues: IUpdateResumeDto = {
    info: resume?.info || '',
    language:
      resume?.language || (i18n.language.substring(0, 2) as ResumeLanguageCode),
    style: resume?.style || ResumeStyles[0],
  };

  const confirmBack = (confirm: boolean) => {
    if (confirm) {
      openConfirmModal({
        title: t('create.step2.confirmBackTitle'),
        children: <Text size="sm">{t('create.step2.confirmBack')}</Text>,
        labels: {
          confirm: t('create.step2.confirmBackConfirm'),
          cancel: t('create.step2.confirmBackCancel'),
        },
        onConfirm: onBack,
      });
    } else {
      onBack();
    }
  };

  const onSubmit: (
    values: IUpdateResumeDto,
    formikHelpers: FormikHelpers<IUpdateResumeDto>,
  ) => Promise<void> = async (values, actions) => {
    try {
      await updateMutation.mutateAsync(values);
      onComplete();
    } catch (error) {
      console.error(error);
      actions.setStatus(error);
      actions.setSubmitting(false);
      onError(error);
    }
  };

  const selectLanguageData = (
    Object.keys(ResumeLanguage) as (keyof typeof ResumeLanguage)[]
  ).map((key) => ({
    value: key,
    label: t(`languages.${key}`),
  }));

  const selectStyleData = ResumeStyles.map((style) => ({
    value: style,
    label: t(`styles.${style}`),
  }));

  return !resume ? (
    <LoadingOverlay visible={true} />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={ReviewResumeSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        dirty,
        isValid,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Stack>
            <Stack align="center" mt="xl">
              <Title order={3}>{t('create.step2.pageTitle')}</Title>
              <Text fz="lg">{t('create.step2.descriptionLine1')}</Text>
            </Stack>

            <Stack p="md">
              <Select
                data={selectStyleData}
                label={t('create.step2.styleLabel')}
                description={t('create.step2.styleDescription')}
                {...getFieldProps('style')}
                onChange={async (value) => {
                  await setFieldValue('style', value);
                }}
                allowDeselect={false}
              />

              <Textarea
                autosize
                minRows={3}
                description={t('create.step2.infoDescription')}
                placeholder={t('create.step2.infoPlaceholder')}
                label={t('create.step2.infoLabel')}
                {...getFieldProps('info')}
                error={touched.info && errors.info}
              />

              <Select
                data={selectLanguageData}
                label={t('create.step2.languageLabel')}
                description={t('create.step2.languageDescription')}
                {...getFieldProps('language')}
                onChange={async (value) => {
                  await setFieldValue('language', value);
                }}
                allowDeselect={false}
              />
            </Stack>

            <Group justify="space-between" my="xl">
              <Button
                leftSection={<IconChevronLeft />}
                onClick={() => {
                  confirmBack(dirty);
                }}
              >
                {isSubmitting ? t('labels.saving') : t('labels.back')}
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                rightSection={<IconChevronRight />}
                disabled={!(isValid && resumeId)}
              >
                {isSubmitting ? t('labels.saving') : t('labels.save')}
              </Button>
            </Group>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
