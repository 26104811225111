import type { IUser } from './User';

export interface IUserProfileSection {
  id?: string;
  title: string;
  company: string;
  from?: string;
  to?: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUserLanguage {
  id: string;
  name: string;
  level: string;
}

export enum ProfileStatus {
  Ok = 'ok',
  Importing = 'importing',
}

export interface IUserProfile {
  status: ProfileStatus;

  firstName?: string;

  lastName?: string;

  displayEmail?: string;

  phone?: string;

  address?: string;

  avatar?: string;

  linkedIn?: string;

  github?: string;

  website?: string;

  intro?: string;

  languages: IUserLanguage[];

  sections?: IUserProfileSection[];

  userId?: IUser['id'];

  createdAt: string;

  updatedAt?: string;
}
