import { Avatar, AvatarProps } from '@mantine/core';
import md5 from 'md5';
import React, { useMemo } from 'react';
import { useProfileQuery } from '../../models/ProfileQueries';
import { useUserQuery } from '../../models/User';

const GRAVATAR_TYPE = 'robohash';

export const UserAvatar: React.FC<AvatarProps> = (props) => {
  const { data: user } = useUserQuery();
  const { data: profile } = useProfileQuery();

  const avatarUrl = useMemo(() => {
    if (!user) return null;
    return (
      profile?.avatar ||
      `https://www.gravatar.com/avatar/${md5(
        user.email.trim().toLowerCase(),
      )}?d=${GRAVATAR_TYPE}`
    );
  }, [user?.email, profile?.avatar]);

  return (
    <Avatar
      radius="xl"
      alt="User avatar"
      size="md"
      src={avatarUrl}
      {...props}
    />
  );
};
