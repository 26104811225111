import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconLink,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IUserProfile } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { ProfileInput } from './ProfileInput';
import { ProfileModal } from './ProfileModal';

type LinksProfileValues = Pick<IUserProfile, 'linkedIn' | 'github' | 'website'>;

const LinksInfoSchema = Yup.object().shape({
  linkedIn: Yup.string().optional(),
  github: Yup.string().optional(),
  website: Yup.string().optional(),
});

export const LinksModal: React.FC<{ opened: boolean; onClose: () => void }> = ({
  opened,
  onClose,
}) => {
  const { t } = useTranslation();
  const { data: profile } = useProfileQuery();

  const initialValues = {
    linkedIn: profile?.linkedIn ?? '',
    github: profile?.github ?? '',
    website: profile?.website ?? '',
  } as LinksProfileValues;

  return (
    <ProfileModal<LinksProfileValues>
      opened={opened}
      onClose={onClose}
      title={t('profile.links')}
      initialValues={initialValues}
      validationSchema={LinksInfoSchema}
    >
      {(formikProps) => (
        <>
          <ProfileInput
            name="linkedIn"
            icon={<IconBrandLinkedin />}
            formikProps={formikProps}
          />
          <ProfileInput
            name="github"
            icon={<IconBrandGithub />}
            formikProps={formikProps}
          />
          <ProfileInput
            name="website"
            icon={<IconLink />}
            formikProps={formikProps}
          />
        </>
      )}
    </ProfileModal>
  );
};
