import { Divider, Stack, Text } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppRouteURL } from '../../AppRouteURL';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { SignInWithAppleButton } from '../../components/SignInWithAppleButton/SignInWithAppleButton';
import { SignInWithGoogleButton } from '../../components/SignInWithGoogleButton/SignInWithGoogleButton';
import { SignInWithLinkedInButton } from '../../components/SignInWithLinkedInButton/SignInWithLinkedInButton';
import { LINKEDIN_ENABLED } from '../../Config';
import { PRODUCT_NAME } from '../../Constants';
import { LoginSignupPage } from '../LoginSignupPage/LoginSignupPage';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoginSignupPage
      title={t('login.signInTo', { brand: PRODUCT_NAME })}
      footerText={t('login.noAccountQuestion')}
      footerLink={AppRouteURL.signup}
      footerLinkText={t('login.signUp', { brand: PRODUCT_NAME })}
    >
      <Stack align="center">
        <SignInWithGoogleButton text="signin_with" />
        <SignInWithAppleButton text="sign-in" />
        {LINKEDIN_ENABLED && (
          <SignInWithLinkedInButton text="signInWithLinkedIn" />
        )}
      </Stack>

      <Divider
        my="lg"
        color="gray.6"
        labelPosition="center"
        label={<Text size="md">{t('login.or')}</Text>}
      />

      <LoginForm />
    </LoginSignupPage>
  );
};
