import { useEffect, useState } from 'react';
import urlJoin from 'url-join';
import { ApiRoutes } from '../../ApiRoutes';
import { API_URL } from '../../Config';

interface ParamsType {
  state?: string;
  code?: string;
  error?: string;
  error_description?: string;
  success?: string;
}

function parse(search: string): ParamsType {
  const query = search.substring(1);
  const vars = query.split('&');
  const parsed: Record<string, string> = {};
  for (const item of vars) {
    const pair = item.split('=');
    if (pair.length > 1) {
      parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }
  return parsed;
}

export const SignInWithLinkedInCallback: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  // const navigate = useNavigate();

  useEffect(() => {
    const params = parse(window.location.search);

    if (params.error) {
      setErrorMessage(
        params.error_description || 'Login failed. Please try again.',
      );
    }

    if (params.success === 'true') {
      window.close();
    } else if (params.code) {
      const url = new URL(API_URL);
      url.pathname = urlJoin('/', 'api', ApiRoutes.SigninWithLinkedInCallback);
      url.searchParams.set('code', params.code);

      console.log(`navigating to ${url.toString()}`);

      window.location.href = url.toString();
    }
  }, []);

  return <div>{errorMessage}</div>;
};
