import { Container, LoadingOverlay } from '@mantine/core';
import * as Sentry from '@sentry/react';
import React, { Suspense, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useAuth } from './Auth';
import getRoutes from './routes/Routes';

// const log = getLogger('AppRoutes');

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const AppRoutes: React.FC = () => {
  const { user, loading } = useAuth();
  const browserRouter = useMemo(
    () => sentryCreateBrowserRouter(getRoutes(loading, user)),
    [loading, user],
  );

  return (
    <Suspense
      fallback={
        <Container>
          <LoadingOverlay visible loaderProps={{ size: 'xl' }} />
        </Container>
      }
    >
      <RouterProvider router={browserRouter} />
    </Suspense>
  );
};
