import {
  Alert,
  Anchor,
  Button,
  Center,
  Group,
  Progress,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  IconAbacus,
  IconAlertTriangle,
  IconChevronLeft,
  IconLinkOff,
  IconPlayerPlay,
  IconRotate2,
  IconX,
} from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume, ResumeStatus } from '../../models/Resume';
import {
  QUERY_KEY_RESUMES,
  useSuggestionMutation,
} from '../../queries/ResumeQueries';
import { getLogger } from '../../services/Logger';
import Tracking from '../../services/Tracking';

const log = getLogger('GenerateResumeForm');

const RESUME_REFETCH_INTERVAL = 10 * 1000;

const statusProgress = {
  [ResumeStatus.New]: 0,
  [ResumeStatus.Preparing]: 10,
  [ResumeStatus.Generating]: 30,
  [ResumeStatus.CoverLetter]: 70,
  [ResumeStatus.Ok]: 100,
  [ResumeStatus.Error]: 100,
  [ResumeStatus.InvalidURL]: 100,
};

export const GenerateResumeForm: React.FC<{
  resumeId: IResume['id'];
  onBack: () => void;
}> = ({ resumeId, onBack }) => {
  const suggestionMutation = useSuggestionMutation(resumeId);
  const navigate = useNavigate();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { data: resume } = useQuery({
    queryKey: [...QUERY_KEY_RESUMES, resumeId],
    queryFn: () =>
      Api.ky.get(`${ApiRoutes.Resumes}/${resumeId}`).json<IResume>(),
    refetchInterval: isGenerating ? RESUME_REFETCH_INTERVAL : false,
  });

  useEffect(() => {
    if (
      resume &&
      (resume.status === ResumeStatus.Preparing ||
        resume.status === ResumeStatus.Generating ||
        resume.status === ResumeStatus.CoverLetter)
    ) {
      setIsGenerating(true);
      setIsError(false);
    } else if (resume?.status === ResumeStatus.Ok) {
      setIsGenerating(false);
      setIsError(false);
      queryClient
        .invalidateQueries({ queryKey: QUERY_KEY_RESUMES })
        .catch((err) => {
          log.warn(`Failed to invalidate queries: ${err}`);
        })
        .finally(() => {
          navigate(`${AppRouteURL.resumes.view}/${resumeId}`);
        });
    } else if (
      resume?.status === ResumeStatus.Error ||
      resume?.status === ResumeStatus.InvalidURL
    ) {
      setIsGenerating(false);
      setIsError(true);
    }
  }, [resume]);

  const createSuggestion = async () => {
    setIsGenerating(true);
    Tracking.event('generate');
    try {
      await suggestionMutation.mutateAsync();
      showNotification({
        id: 'suggestion',
        title: t('create.step3.generatingTitle'),
        message: t('create.step3.generatingMessage'),
        icon: <IconAbacus />,
        loading: true,
        autoClose: 5000,
      });
    } catch (error) {
      let message = <Text>{t('create.step3.generatingFailed')}</Text>;
      if (error instanceof HTTPError && error.response.status === 429) {
        message = (
          <Group>
            <Text>{t('view.rateLimitExceeded')}</Text>
            <Anchor href={AppRouteURL.account}>
              {t('view.upgradeAccount')}
            </Anchor>
          </Group>
        );
        setDisabled(true);
      }

      showNotification({
        title: t('create.step3.generatingErrorTitle'),
        message,
        color: 'red',
        icon: <IconX />,
        loading: false,
        autoClose: false,
      });
      setIsGenerating(false);
    }
  };

  return (
    <>
      <Stack gap="xl" align="center" p="xl" my="xl">
        <Title order={3}>
          {isGenerating
            ? t('create.step3.pageTitleGenerating')
            : t('create.step3.pageTitleGenerate')}
        </Title>

        {isGenerating ? (
          <Stack gap="xl" align="center">
            <Progress
              value={statusProgress[resume?.status ?? ResumeStatus.New]}
              radius="lg"
              size="xl"
              animated
            />
            <Text size="lg" fw={600} ta="center">
              {t(`application.status.${resume?.status ?? ResumeStatus.New}`)}
            </Text>
            <Text ta="center">{t('create.step3.generatingDescription')}</Text>
          </Stack>
        ) : (
          <Stack gap="xl" align="center">
            <Text ta="center">{t('create.step3.descriptionLine1')}</Text>
            <Text ta="center">{t('create.step3.descriptionLine2')}</Text>
          </Stack>
        )}
      </Stack>

      <Stack gap="xl">
        {resume?.status === ResumeStatus.Error && (
          <Alert color="red" icon={<IconAlertTriangle />}>
            {t('create.step3.generatingFailed')}
          </Alert>
        )}

        {resume?.status === ResumeStatus.InvalidURL && (
          <Alert color="red" icon={<IconLinkOff />}>
            {t('create.step3.invalidURL')}
          </Alert>
        )}

        <Center>
          <Button
            size={isSmallScreen ? 'md' : 'xl'}
            m="xl"
            radius="xl"
            leftSection={isError ? <IconRotate2 /> : <IconPlayerPlay />}
            onClick={
              !isGenerating
                ? isError
                  ? () => {
                      navigate(
                        `${AppRouteURL.resumes.create}/0?resumeId=${resumeId}`,
                      );
                    }
                  : createSuggestion
                : undefined
            }
            loading={isGenerating}
            disabled={disabled}
            style={{ boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.75)' }}
          >
            {isGenerating
              ? t('create.step3.generatingButtonLabel')
              : isError
                ? t('create.step3.restartButtonLabel')
                : t('create.step3.generateButtonLabel')}
          </Button>
        </Center>

        <div>
          <Button
            size="sm"
            leftSection={<IconChevronLeft />}
            onClick={onBack}
            disabled={isGenerating}
          >
            {t('labels.back')}
          </Button>
        </div>
      </Stack>
    </>
  );
};
