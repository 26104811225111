import { Container, Divider, Paper, Stack, Text } from '@mantine/core';
import { IconScript } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WhatIsThis from '../../components/WhatIsThis/WhatIsThis';
import { useProfileQuery } from '../../models/ProfileQueries';
import { IntroModal } from './IntroModal';
import { MultilineText } from './MultilineText';
import { TitleWithEditButton } from './TitleWithEditButton';

export const IntroTab: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { data: profile } = useProfileQuery();
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <Container my="lg" px={0}>
      <Stack>
        <WhatIsThis message={t('profile.introHelp')} />

        <Paper
          p="md"
          shadow="sm"
          onClick={() => {
            setModalOpened(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          <TitleWithEditButton
            onClick={() => {
              setModalOpened(true);
            }}
            title={t('profile.intro')}
            disabled={disabled}
            icon={<IconScript size={18} />}
          />
          <Text size="xs" c="dimmed">
            {t('profile.introDescription')}
          </Text>
          <Divider mt="xs" mb="md" />
          <Stack>
            <MultilineText
              c={!profile?.intro ? 'dimmed' : ''}
              fs={!profile?.intro ? 'italic' : ''}
              text={profile?.intro || t('profile.introPlaceholder')}
            />
          </Stack>
        </Paper>

        <IntroModal
          opened={modalOpened}
          onClose={() => {
            setModalOpened(false);
          }}
        />
      </Stack>
    </Container>
  );
};
