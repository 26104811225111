import { Box, Button, Tooltip, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCirclePlus } from '@tabler/icons-react';
import React, { MouseEventHandler } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { isProfileEmpty } from '../../models/ProfileQueries';

import classes from './CreateResumeButton.module.css';

export const CreateResumeButton: React.FC<{
  onClick?: MouseEventHandler | undefined;
}> = ({ onClick }) => {
  const profileEmpty = isProfileEmpty();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const isMediumScreen = useMediaQuery(
    `(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})`,
  );

  return (
    <Box>
      <Tooltip
        label={
          profileEmpty ? (
            <Trans i18nKey="applications.missingProfileInfo">
              You need to
              <span>update your profile</span>
              before you can start creating applications!
            </Trans>
          ) : (
            t('applications.newApplication')
          )
        }
      >
        <Button
          component={Link}
          to={AppRouteURL.resumes.create}
          leftSection={<IconCirclePlus />}
          onClick={onClick}
          classNames={{ root: classes.createResumeButton }}
        >
          {isMediumScreen ? t('labels.new') : t('applications.newApplication')}
        </Button>
      </Tooltip>
    </Box>
  );
};
