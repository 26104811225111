import { ActionIcon, BoxProps, Group, Title } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import React from 'react';

export const TitleWithEditButton: React.FC<
  {
    title: string;
    onClick: () => void;
    icon?: React.ReactNode;
    disabled?: boolean;
  } & BoxProps
> = ({ title, onClick, icon, disabled = false, ...boxProps }) => (
  <Group justify="space-between" wrap="nowrap" {...boxProps}>
    <Group gap={4}>
      {icon}
      <Title order={4}>{title}</Title>
    </Group>
    <ActionIcon
      variant="subtle"
      onClick={onClick}
      size="md"
      disabled={disabled}
    >
      <IconPencil />
    </ActionIcon>
  </Group>
);
