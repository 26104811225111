import { Button, Paper, Stack, Text, Title } from '@mantine/core';
import { IconWand } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';

import classes from './HomeCard.module.css';

const AnalyzerCard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper
      shadow="lg"
      radius="xl"
      p="xl"
      onClick={() => {
        navigate(AppRouteURL.analyze);
      }}
      className={classes.homeCard}
    >
      <div className={classes.homeCardWrapper}>
        <Title order={5}>{t('analyze.pageTitle')}</Title>

        <div className={classes.homeCardContent}>
          <Stack align="center">
            <Text ta="center">{t('home.analyzeResumeCTA')}</Text>
            <Button
              radius="xl"
              leftSection={<IconWand />}
              component={Link}
              to={AppRouteURL.analyze}
            >
              {t('home.goToAnalyzerButton')}
            </Button>
          </Stack>
        </div>
      </div>
    </Paper>
  );
};

export default AnalyzerCard;
