import { Button, Modal, Stack } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage, uiLanguages } from '../../i18n';
import { useUpdateUserMutation } from '../../queries/UserQueries';
import { getLogger } from '../../services/Logger';

const log = getLogger('LanguageSwitcherModal');

export const LanguageSwitcherModal: React.FC<
  React.ComponentProps<typeof Modal>
> = ({ opened, onClose }) => {
  const { t, i18n } = useTranslation();
  const updateMutation = useUpdateUserMutation();

  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    uiLanguages.find((entry) => i18n.language.startsWith(entry)) ||
      uiLanguages[0],
  );

  const updateLanguageSetting = async (language: string) => {
    try {
      setSelectedLanguage(
        uiLanguages.find((entry) => entry === language) || uiLanguages[0],
      );
      await changeLanguage(language);
      // TODO cannot save for unauthorized users
      await updateMutation.mutateAsync({ language });
    } catch (error) {
      log.error('Error while updating language:', error);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose}>
      <Modal.Header>{t('userMenu.changeLanguage')}</Modal.Header>
      <Modal.Body>
        <Stack>
          {uiLanguages.map((lang) => (
            <Button
              key={lang}
              variant={selectedLanguage === lang ? 'filled' : 'light'}
              onClick={async () => {
                onClose();
                await updateLanguageSetting(lang);
              }}
            >
              {t(`languages.${lang}`)}
            </Button>
          ))}
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
