import { Button, Container, Stack, Text, Textarea, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconUserExclamation } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { getLogger } from '../../services/Logger';

const log = getLogger('FeedbackPage');

const FeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const mutation = useMutation({
    mutationFn: (text: string) =>
      Api.ky.post(ApiRoutes.Feedback, { json: { text } }),
  });

  const initialValues = {
    text: '',
  };

  const onSubmit: (
    values: { text: string },
    formikHelpers: FormikHelpers<{ text: string }>,
  ) => Promise<void> = async ({ text }) => {
    try {
      await mutation.mutateAsync(text);
      showNotification({
        title: t('translation:feedback.successTitle'),
        message: t('translation:feedback.successMessage'),
      });
    } catch (error) {
      log.error(error);
      showNotification({
        title: t('translation:feedback.errorTitle'),
        message: t('translation:feedback.errorMessage'),
      });
    } finally {
      navigate(AppRouteURL.home);
    }
  };

  return (
    <Container size="sm">
      <Bowl title={t('translation:feedback.pageTitle')} />

      <Stack gap="xl">
        <Title>{t('translation:feedback.pageTitle')}</Title>

        <Text>{t('translation:feedback.description')}</Text>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            errors,
            dirty,
            touched,
            isValid,
            isSubmitting,
            handleSubmit,
            getFieldProps,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Stack gap="lg">
                <Textarea
                  label={t('translation:feedback.messageLabel')}
                  leftSection={<IconUserExclamation />}
                  withAsterisk
                  minRows={10}
                  autosize
                  placeholder={t('translation:feedback.messagePlaceholder')}
                  {...getFieldProps('text')}
                  error={touched.text && errors.text}
                />

                <Button
                  type="submit"
                  size="md"
                  variant="filled"
                  loading={isSubmitting}
                  leftSection={<IconCheck />}
                  disabled={!(dirty && !isSubmitting && isValid)}
                >
                  {t('translation:feedback.submitButton')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Container>
  );
};

export default FeedbackPage;
