import { Stack, Textarea } from '@mantine/core';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IUserProfile } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { ProfileModal } from './ProfileModal';

type IntroProfileValues = Pick<IUserProfile, 'intro'>;

const IntroSchema = Yup.object().shape({
  intro: Yup.string().optional(),
});

export const IntroModal: React.FC<{
  opened: boolean;
  onClose: () => void;
}> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const { data: profile } = useProfileQuery();

  const initialValues = {
    intro: profile?.intro ?? '',
  } as IntroProfileValues;

  return (
    <ProfileModal<IntroProfileValues>
      opened={opened}
      onClose={onClose}
      title={t('profile.editIntro')}
      initialValues={initialValues}
      validationSchema={IntroSchema}
      modalProps={{ size: 'xl' }}
    >
      {(formikProps: FormikProps<IntroProfileValues>) => (
        <Stack>
          <Textarea
            autosize
            minRows={8}
            placeholder={t('profile.introPlaceholder')}
            label={t('profile.intro')}
            error={
              formikProps.touched.intro && formikProps.errors.intro
                ? formikProps.errors.intro
                : null
            }
            withAsterisk
            {...formikProps.getFieldProps('intro')}
          />
        </Stack>
      )}
    </ProfileModal>
  );
};
