import { Paper, Title } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import SubscriptionInfo from '../../components/SubscriptionInfo/SubscriptionInfo';
import { SubscriptionType, useUserQuery } from '../../models/User';

import classes from './HomeCard.module.css';

const SubscribeCard: React.FC = () => {
  const { data: user } = useUserQuery();

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper
      shadow="lg"
      radius="xl"
      p="xl"
      onClick={() => {
        navigate(
          user?.subscriptionType === SubscriptionType.PRO
            ? AppRouteURL.account
            : AppRouteURL.subscribe,
        );
      }}
      className={classes.homeCard}
    >
      <div className={classes.homeCardWrapper}>
        <Title order={5}>{t('account.subscriptionTitle')}</Title>

        <div className={classes.homeCardContent}>
          <SubscriptionInfo />
        </div>
      </div>
    </Paper>
  );
};

export default SubscribeCard;
