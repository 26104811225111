import { Anchor, List, Text, TextProps, Title } from '@mantine/core';
import React from 'react';
import Markdown from 'react-markdown';

export const MultilineText: React.FC<{ text: string } & TextProps> = ({
  text,
}) => {
  return (
    <Markdown
      components={{
        h1: ({ children }) => <Title order={1}>{children}</Title>,
        h2: ({ children }) => <Title order={2}>{children}</Title>,
        h3: ({ children }) => <Title order={3}>{children}</Title>,
        h4: ({ children }) => <Title order={4}>{children}</Title>,
        h5: ({ children }) => <Title order={5}>{children}</Title>,
        h6: ({ children }) => <Title order={6}>{children}</Title>,
        ul: ({ children }) => <List>{children}</List>,
        li: ({ children }) => <List.Item>{children}</List.Item>,
        p: ({ children }) => <Text>{children}</Text>,
        a: ({ children, href }) => <Anchor href={href}>{children}</Anchor>,
      }}
    >
      {text}
    </Markdown>
  );
};
