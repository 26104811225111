import { Anchor, Container, Stack, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconShield } from '@tabler/icons-react';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bowl } from '../../components/Bowl/Bowl';
import {
  UpdateUserValues,
  useUpdateUserMutation,
} from '../../queries/UserQueries';
import Tracking from '../../services/Tracking';
import { DeleteAccountPopup } from './DeleteAccountPopup';
import { EditAccountForm } from './EditAccountForm';
import { SubscribePopup } from './SubscribePopup';

// const log = getLogger('EditAccountPage');

const EditAccountPage: React.FC = () => {
  const { t } = useTranslation();

  const updateMutation = useUpdateUserMutation();
  const onSubmit: (
    values: UpdateUserValues,
    formikHelpers: FormikHelpers<UpdateUserValues>,
  ) => Promise<void> = async (values, actions) => {
    Tracking.event('profile_updated');
    // TODO ask for confirmation when email address was changed
    try {
      await updateMutation.mutateAsync({
        username: values.username || undefined,
        email: values.email,
        password: values.password || undefined,
      });
      showNotification({
        title: t('translation:account.updatedTitle'),
        message: t('translation:account.updatedMessage'),
        icon: <IconShield />,
      });
    } catch (error) {
      console.error(error);
      actions.setStatus(error);
      actions.setSubmitting(false);
    } finally {
      actions.resetForm();
    }
  };

  return (
    <Container size="xs">
      <Bowl title={t('account.pageTitle')} />
      <Stack pb="xl">
        <Title order={2}>{t('account.pageTitle')}</Title>
        <Title order={3} mt="lg">
          {t('account.basicInfoTitle')}
        </Title>
        <EditAccountForm onSubmit={onSubmit} />
        <SubscribePopup />
        <Title order={3} mt="lg">
          {t('account.otherSettings')}
        </Title>
        <Stack>
          <Anchor
            size="sm"
            href="#"
            className="termly-display-preferences"
            onClick={() => {
              window.displayPreferenceModal();
              return false;
            }}
          >
            {t('account.consentPreferences')}
          </Anchor>
          <DeleteAccountPopup />
        </Stack>
      </Stack>
    </Container>
  );
};

export default EditAccountPage;
