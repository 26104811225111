import { Anchor, Center, Container, Stack, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { PRODUCT_NAME } from '../../Constants';
import { useUserQuery } from '../../models/User';
import { useProductQuery } from '../../queries/ProductQueries';
import { getLogger } from '../../services/Logger';
import Tracking from '../../services/Tracking';

const log = getLogger('CheckoutSuccessPage');

export const CheckoutSuccessPage: React.FC = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { data: user, refetch: refetchUser } = useUserQuery();
  const { data: products } = useProductQuery();

  const sessionId = params.get('sessionId') || user?.id || 'unknown';

  useEffect(() => {
    refetchUser().catch((e) => log.error(e));
  }, []);

  useEffect(() => {
    if (products) {
      const priceId = params.get('priceId') || 'unknown';
      const currency = params.get('currency') || 'eur';

      const product = products.find((p) => p.id === priceId);
      const value =
        (product?.currencies[currency]?.unitAmount ?? 100.0) / 100.0;

      Tracking.trackPurchase({
        itemId: priceId || 'unknown',
        currency,
        value,
        transactionId: sessionId,
      });
    }
  }, [products]);

  return (
    <Container size="xs">
      <Stack gap="xl">
        <Bowl title={t('translation:checkout.successPageTitle')} />

        <Center>
          <Title order={2}>{t('translation:checkout.successPageTitle')}</Title>
        </Center>

        <Center>
          <Text fz="xl">{t('checkout.thankYou')}</Text>
        </Center>

        <Center>
          <Anchor component={Link} to={AppRouteURL.home}>
            {t('checkout.homeLink', { brand: PRODUCT_NAME })}
          </Anchor>
        </Center>

        <Text fz="xs" fw={200} mt="xl">
          {t('checkout.sessionId', { sessionId })}
        </Text>
      </Stack>
    </Container>
  );
};
