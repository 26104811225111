import {
  Anchor,
  Button,
  Center,
  Container,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconArrowBackUp } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL, ExternalURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { Logo } from '../../components/Logo/Logo';
import { PRODUCT_NAME } from '../../Constants';

const AboutPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container size="xs" p="lg">
      <Bowl title={t('about.pageTitle', { brand: PRODUCT_NAME })} />

      <Button
        mb="xl"
        ml={-10}
        leftSection={<IconArrowBackUp />}
        variant="subtle"
        size="xs"
        component={Link}
        to={AppRouteURL.home}
      >
        {t('labels.back')}
      </Button>

      <Stack gap="xl">
        <Center>
          <Logo type="Square" h={320} />
        </Center>

        <Center>
          <Title order={2}>
            {t('about.pageTitle', { brand: PRODUCT_NAME })}
          </Title>
        </Center>

        <Center>
          <Text fz="lg">{t('about.copyright', { brand: PRODUCT_NAME })}</Text>
        </Center>

        <Center>
          <Anchor href={ExternalURL.imprint}>{t('about.imprint')}</Anchor>
        </Center>
        <Center>
          <Anchor href={AppRouteURL.terms}>
            {t('translation:about.termsTitle')}
          </Anchor>
        </Center>
        <Center>
          <Anchor href={AppRouteURL.privacyPolicy}>
            {t('translation:about.privacyTitle')}
          </Anchor>
        </Center>
        <Center>
          <Anchor href={AppRouteURL.cookiePolicy}>
            {t('translation:about.cookieTitle')}
          </Anchor>
        </Center>
        <Center>
          <Anchor href={AppRouteURL.disclaimer}>
            {t('translation:about.disclaimerTitle')}
          </Anchor>
        </Center>
        <Center>
          <Anchor href={AppRouteURL.acceptableUse}>
            {t('translation:about.acceptableUseTitle')}
          </Anchor>
        </Center>

        <Button
          mt="lg"
          size="lg"
          component={Link}
          to={AppRouteURL.home}
          leftSection={<IconArrowBackUp />}
        >
          {t('about.returnButton')}
        </Button>
      </Stack>
    </Container>
  );
};

export default AboutPage;
