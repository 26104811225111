import { Button, Stack, TextInput } from '@mantine/core';

import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';

export const ForgotPasswordForm: React.FC = () => {
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('translation:errors.invalidEmail'))
      .required(t('translation:errors.required')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async ({ email }, actions) => {
      try {
        await resetPassword(email);
        // TODO handle successful password reset
        navigate(AppRouteURL.home);
        navigate(0);
      } catch (error) {
        console.error(error);
        actions.setStatus(error);
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack gap="xl">
          <TextInput
            type="email"
            placeholder={t('translation:forgotPassword.emailPlaceholder')}
            label={t('translation:forgotPassword.emailLabel')}
            description={t('translation:forgotPassword.emailDescription')}
            {...getFieldProps('email')}
            error={touched.email && errors.email ? errors.email : null}
          />

          <Button
            fullWidth
            size="lg"
            type="submit"
            variant="filled"
            loading={isSubmitting}
          >
            {t('translation:forgotPassword.submitButton')}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
