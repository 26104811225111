import { Anchor, Center, Container, Text, Title } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Bowl } from '../../components/Bowl/Bowl';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import { Logo } from '../../components/Logo/Logo';

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

export const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container size="sm" p="xl">
      <Bowl title={t('translation:forgotPassword.pageTitle')} />
      <Center my="lg">
        <Logo type="Banner" />
      </Center>

      <Title order={1} ta="center">
        {t('translation:forgotPassword.pageTitle')}
      </Title>

      <ForgotPasswordForm />

      <Text component={motion.p} {...fadeInUp} fz="sm" mt="sm" ta="center">
        {t('translation:forgotPassword.noAccountQuestion')}{' '}
        <Anchor fz="sm" component={RouterLink} to="/signup">
          {t('translation:forgotPassword.signUp')}
        </Anchor>
      </Text>
    </Container>
  );
};
