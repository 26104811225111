import {
  Alert,
  Button,
  Container,
  Group,
  Loader,
  Skeleton,
  Stack,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconFileImport,
  IconFlare,
  IconListDetails,
  IconUser,
} from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { IUserProfile, ProfileStatus } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { getLogger } from '../../services/Logger';
import { BasicInfoTab } from './BasicInfoTab';
import { IntroTab } from './IntroTab';
import { TimelineTab } from './TimelineTab';

const logger = getLogger('ProfilePage');

export type UserProfileValues = Omit<
  IUserProfile,
  'createdAt' | 'updatedAt' | 'status'
>;

const ProfilePage: React.FC = () => {
  const { data: profile, isPending, refetch } = useProfileQuery();
  const { tab } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const disableEditing = profile?.status === ProfileStatus.Importing;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const checkProfile = () => {
      if (profile?.status === ProfileStatus.Importing) {
        refetch()
          .then(() => {
            timeout = setTimeout(checkProfile, 3000);
          })
          .catch((err) => {
            logger.error(err);
          });
      }
    };

    if (profile?.status === ProfileStatus.Importing) {
      timeout = setTimeout(checkProfile, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [profile?.status]);

  const onTabChange: (value: string | null) => void = (value) => {
    navigate(`${AppRouteURL.profile}/${value!}`);
  };

  return (
    <Container p={0}>
      <Bowl title={t('profile.pageTitle')} />
      <Stack>
        {profile?.status === ProfileStatus.Importing && (
          <Alert
            title={t('import.importingTitle')}
            icon={<Loader size="sm" type="dots" />}
          >
            <Text>{t('profile.importing')}</Text>
          </Alert>
        )}
        {isPending ? (
          <Skeleton height={320} />
        ) : (
          <>
            <Group justify="space-between" mb="lg">
              <Title order={2}>{t('profile.pageTitle')}</Title>
              <Button
                id="button-profile-import"
                variant="outline"
                component={Link}
                to={AppRouteURL.profileImport}
                leftSection={<IconFileImport />}
                disabled={
                  !profile || profile.status === ProfileStatus.Importing
                }
              >
                {t('profile.importLabel')}
              </Button>
            </Group>

            <Tabs value={tab ?? 'basic'} onChange={onTabChange}>
              <Tabs.List>
                <Tabs.Tab
                  value="basic"
                  leftSection={!isSmallScreen && <IconUser size={16} />}
                >
                  <Text size="sm" id="profile-page-basic-tab">
                    {t('profile.personal')}
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab
                  value="intro"
                  leftSection={!isSmallScreen && <IconFlare size={16} />}
                >
                  <Text size="sm" id="profile-page-intro-tab">
                    {t('profile.intro')}
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab
                  value="timeline"
                  leftSection={!isSmallScreen && <IconListDetails size={16} />}
                >
                  <Text size="sm" id="profile-page-timeline-tab">
                    {t('profile.timelineLabel')}
                  </Text>
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="basic">
                <BasicInfoTab disabled={disableEditing} />
              </Tabs.Panel>

              <Tabs.Panel value="intro">
                <IntroTab disabled={disableEditing} />
              </Tabs.Panel>

              <Tabs.Panel value="timeline">
                <TimelineTab disabled={disableEditing} />
              </Tabs.Panel>
            </Tabs>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default ProfilePage;
