import {
  Alert,
  Anchor,
  Button,
  Center,
  Container,
  Group,
  Loader,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { IconCirclePlus, IconInfoCircle } from '@tabler/icons-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { isProfileEmpty } from '../../models/ProfileQueries';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { ResumeTableRow } from './ResumeTableRow';

// const log = getLogger('ListResumesPage');

const EmptyProfileWarning: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Center m="lg">
      <Alert icon={<IconInfoCircle />} title={t('applications.missingProfile')}>
        <Text size="lg">
          <Trans i18nKey="applications.missingProfileInfo">
            You need to
            <Anchor component={Link} to={AppRouteURL.profileTabBasic}>
              update your profile
            </Anchor>
            before you can start creating applications!
          </Trans>
        </Text>
      </Alert>
    </Center>
  );
};

const NoResumesAlert: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Center m="lg">
      <Alert icon={<IconInfoCircle />} title={t('applications.noApplications')}>
        <Text size="lg">
          <Trans i18nKey="applications.noApplicationsInfo">
            You have not created any applications yet. Go ahead and
            <Anchor component={Link} to={AppRouteURL.resumes.create}>
              create one
            </Anchor>
            !
          </Trans>
        </Text>
      </Alert>
    </Center>
  );
};

export const ListResumesPage: React.FC = () => {
  const profileEmpty = isProfileEmpty();
  const { t } = useTranslation();

  const { data: resumes, isPending } = useResumesQuery();

  const noResumes = !resumes || resumes.length === 0;

  const content = isPending ? (
    <Center>
      <Loader />
    </Center>
  ) : profileEmpty ? (
    <EmptyProfileWarning />
  ) : noResumes ? (
    <NoResumesAlert />
  ) : (
    <Table highlightOnHover striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>{t('applications.title')}</Table.Th>
          <Table.Th>{t('applications.created')}</Table.Th>
          <Table.Th>{t('applications.actions')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {resumes.map((resume) => (
          <ResumeTableRow key={resume.id} resume={resume} />
        ))}
      </Table.Tbody>
    </Table>
  );

  return (
    <Container size="lg" p={0}>
      <Bowl title={t('applications.pageTitle')} />

      <Stack>
        <Group justify="space-between">
          <Title order={2}>{t('applications.pageTitle')}</Title>
          <Button
            component={Link}
            to={AppRouteURL.resumes.create}
            variant="outline"
            leftSection={<IconCirclePlus />}
            disabled={profileEmpty}
          >
            {t('applications.newApplication')}
          </Button>
        </Group>

        {content}
      </Stack>
    </Container>
  );
};
