import {
  Box,
  Button,
  FocusTrap,
  Group,
  Modal,
  ModalProps,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { IconCheck } from '@tabler/icons-react';
import { Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUserProfile } from '../../models/Profile';
import { useProfileMutation } from '../../models/ProfileQueries';

interface ProfileModalProps<T extends Partial<IUserProfile>> {
  opened: boolean;
  onClose: () => void;
  title: string;
  initialValues: FormikConfig<T>['initialValues'];
  validationSchema: FormikConfig<T>['validationSchema'];
  children: (formikProps: FormikProps<T>) => React.ReactNode;
  modalProps?: Partial<ModalProps>;
}

export const ProfileModal = <T extends Partial<IUserProfile>>({
  opened,
  onClose,
  title,
  initialValues,
  validationSchema,
  children,
  modalProps,
}: ProfileModalProps<T>) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const updateProfileMutation = useProfileMutation();

  const onSubmit = (values: T, actions: FormikHelpers<T>) =>
    updateProfileMutation
      .mutateAsync(values)
      .then(() => {
        actions.resetForm({ values });
        onClose();
      })
      .catch((error) => {
        actions.setStatus(error);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });

  const confirmClose = (dirty: boolean) => {
    if (dirty)
      openConfirmModal({
        title: t('profile.unsavedChangesTitle'),
        centered: true,
        children: <Text size="sm">{t('profile.unsavedChangesText')}</Text>,
        labels: {
          confirm: t('labels.close'),
          cancel: t('labels.cancel'),
        },
        confirmProps: { color: 'red' },
        onConfirm: onClose,
      });
    else onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text fz="lg" fw="bolder">
          {title}
        </Text>
      }
      size="xl"
      withCloseButton={false}
      fullScreen={isSmallScreen}
      {...modalProps}
    >
      <Box p={{ base: 0, md: 'md' }}>
        <FocusTrap.InitialFocus />
        {/* eslint-disable @typescript-eslint/no-unsafe-assignment */}
        <Formik<T>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <Stack>{children(formikProps)}</Stack>
              <Group justify="flex-end" mt="xl">
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => {
                    confirmClose(formikProps.dirty);
                  }}
                  disabled={formikProps.isSubmitting}
                >
                  {t('labels.close')}
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="filled"
                  leftSection={<IconCheck />}
                  loading={formikProps.isSubmitting}
                  disabled={
                    !formikProps.isValid ||
                    formikProps.isSubmitting ||
                    !formikProps.dirty
                  }
                >
                  {formikProps.isSubmitting
                    ? t('labels.saving')
                    : t('labels.save')}
                </Button>
              </Group>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
