import { Anchor, Center, Paper, Text, Timeline, Title } from '@mantine/core';
import { IconCertificate, IconUser } from '@tabler/icons-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { PRODUCT_NAME } from '../../Constants';
import { isProfileEmpty } from '../../models/ProfileQueries';

import classes from './HomeCard.module.css';

const RecommendationCard: React.FC = () => {
  const { t } = useTranslation();
  const profileEmpty = isProfileEmpty();

  return (
    <Paper shadow="lg" radius="xl" p="xl" className={classes.homeCard}>
      <Center>
        <Timeline bulletSize={24} active={profileEmpty ? 0 : 1} lineWidth={3}>
          <Timeline.Item
            bullet={<IconUser size={16} />}
            title={
              <Anchor component={Link} to={AppRouteURL.profileTabBasic}>
                <Title order={4}>{t('home.updateProfileCTA')}</Title>
              </Anchor>
            }
          >
            <Text size="md">{t('home.updateProfileCTAInfo')}</Text>
          </Timeline.Item>
          <Timeline.Item
            bullet={<IconCertificate size={16} />}
            title={
              profileEmpty ? (
                <Text fz="xl" fw={600} color="dimmed">
                  {t('home.createJobApplicationCTA')}
                </Text>
              ) : (
                <Anchor component={Link} to={AppRouteURL.resumes.list}>
                  <Title order={4}>{t('home.createJobApplicationCTA')}</Title>
                </Anchor>
              )
            }
          >
            <Text size="md">
              <Trans i18nKey={'home.createJobApplicationCTAInfo'}>
                Paste a job ad you want to apply to and let{' '}
                {{ brand: PRODUCT_NAME }} figure out the rest.
              </Trans>
            </Text>
          </Timeline.Item>
        </Timeline>
      </Center>
    </Paper>
  );
};

export default RecommendationCard;
