import { Button, PasswordInput, Stack, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconChecklist, IconMail } from '@tabler/icons-react';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { PRODUCT_NAME } from '../../Constants';
import Tracking from '../../services/Tracking';

export const SignupForm: React.FC = () => {
  const { signup } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('errors.invalidEmail'))
      .required(t('errors.required')),
    password: Yup.string()
      .min(6, t('errors.minLength', { min: 6 }))
      .required(t('errors.required')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values, actions) => {
      try {
        await signup(values, i18n.languages[0].slice(0, 2));

        Tracking.trackSignup({
          email: values.email,
          method: 'email',
        }).catch(console.error);

        showNotification({
          title: t('signup.emailNotificationTitle'),
          icon: <IconMail />,
          message: t('signup.emailNotificationMessage'),
          autoClose: false,
        });
        navigate(AppRouteURL.home, { replace: true });
      } catch (error) {
        console.error('Error during registration:', error);
        actions.setStatus(error);
        actions.setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    isValid,
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack>
          <Stack mb="lg">
            <TextInput
              type="email"
              label={t('signup.emailLabel')}
              size="md"
              autoComplete="email"
              withAsterisk
              placeholder={t('signup.emailPlaceholder')}
              {...getFieldProps('email')}
              error={touched.email && errors.email}
            />

            <PasswordInput
              withAsterisk
              label={t('signup.passwordLabel')}
              size="md"
              autoComplete="new-password"
              placeholder={t('signup.passwordPlaceholder')}
              {...getFieldProps('password')}
              error={touched.password && errors.password}
            />
          </Stack>

          <Button
            size="lg"
            type="submit"
            variant="filled"
            loading={isSubmitting}
            leftSection={<IconChecklist />}
            disabled={!(!isSubmitting && isValid)}
          >
            {t('signup.submitButton', { brand: PRODUCT_NAME })}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
