import { Container, Image } from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import Tracking from '../../services/Tracking';
import { AppleSignInText, getAppleLocale } from './AppleLocales';

export const SignInWithAppleButton: React.FC<{
  text: AppleSignInText;
}> = ({ text }) => {
  const { i18n } = useTranslation();
  const colorScheme = useColorScheme();

  const buttonUrl = new URL('https://appleid.cdn-apple.com/appleid/button');
  buttonUrl.searchParams.append('border', 'false');
  buttonUrl.searchParams.append('border_radius', '15');
  buttonUrl.searchParams.append(
    'color',
    colorScheme === 'dark' ? 'white' : 'black',
  );
  buttonUrl.searchParams.append('height', '42');
  buttonUrl.searchParams.append('locale', getAppleLocale(i18n.languages[0]));
  buttonUrl.searchParams.append('scale', '2');
  buttonUrl.searchParams.append('type', text);
  buttonUrl.searchParams.append('width', '224');

  return (
    <Container h={42} w={224} p={0}>
      <a
        href={Api.getApiRoute(ApiRoutes.SignInWithApple)}
        onClick={() => {
          if (text === 'sign-up') {
            Tracking.trackSignup({ method: 'apple' }).catch(console.error);
          } else {
            Tracking.trackLogin({ method: 'apple' });
          }
        }}
      >
        <Image src={buttonUrl.toString()} h={42} w={224} />
      </a>
    </Container>
  );
};
