export const truncate = (
  str: string,
  n: number,
  useWordBoundary = true,
  ellipsis = '&hellip;',
) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1);
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(' '))
      : subString) + ellipsis
  );
};

export function hashString(str: string): Promise<string> {
  const utf8 = new TextEncoder().encode(str);
  return crypto.subtle
    .digest('SHA-256', utf8)
    .then((hashBuffer) =>
      Array.from(new Uint8Array(hashBuffer))
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join(''),
    )
    .catch((err) => {
      console.error('Error while hashing string:', err);
      throw err;
    });
}
