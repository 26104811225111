import { Rating } from '@mantine/core';
import React from 'react';

export const AnalysisRating: React.FC<{ rating?: number }> = ({ rating }) =>
  rating !== undefined && (
    <Rating
      defaultValue={
        Number.isNaN(rating) ? 0 : Math.min(10, Math.max(rating, 0))
      }
      size="xs"
      count={10}
      readOnly
    />
  );
