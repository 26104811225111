import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { IconStar } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { useUserQuery } from '../../models/User';
import { useFinishTourMutation } from '../../queries/UserQueries';

const OnboardingModal: React.FC = () => {
  const { data: user } = useUserQuery();

  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const finishTour = useFinishTourMutation();

  const saveTour = () => {
    if (user && !user.toursDone.includes('onboarding')) {
      user.toursDone.push('onboarding');
      finishTour.mutate('onboarding');
      setOpened(false);
    }
  };

  useEffect(() => {
    setOpened(!!user && !user.toursDone.includes('onboarding'));
  }, [user]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      title={
        <Text fz="xl" fw="bold">
          {t('home.onboardingModal.title')}
        </Text>
      }
      centered
      size="lg"
    >
      <Stack align="center" p="lg">
        <Text ta="center">{t('home.onboardingModal.text')}</Text>
        <Button
          onClick={() => {
            saveTour();
            navigate(AppRouteURL.analyze);
          }}
          mt="md"
          leftSection={<IconStar />}
        >
          {t('home.onboardingModal.buttonLabel')}
        </Button>
      </Stack>
      <Group justify="flex-start" mt="md" gap="sm">
        <Button onClick={saveTour} mt="md" variant="transparent">
          {t('labels.close')}
        </Button>
      </Group>
    </Modal>
  );
};

export default OnboardingModal;
