import { Anchor, Divider, Stack, Text } from '@mantine/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { SignupForm } from '../../components/LoginForm/SignupForm';
import { SignInWithAppleButton } from '../../components/SignInWithAppleButton/SignInWithAppleButton';
import { SignInWithGoogleButton } from '../../components/SignInWithGoogleButton/SignInWithGoogleButton';
import { SignInWithLinkedInButton } from '../../components/SignInWithLinkedInButton/SignInWithLinkedInButton';
import { LINKEDIN_ENABLED } from '../../Config';
import { PRODUCT_NAME } from '../../Constants';
import { LoginSignupPage } from '../LoginSignupPage/LoginSignupPage';

export const SignupPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoginSignupPage
      title={t('signup.pageTitle')}
      footerText={t('login.haveAccountQuestion')}
      footerLink={AppRouteURL.login}
      footerLinkText={t('signup.login', { brand: PRODUCT_NAME })}
    >
      <Stack gap="lg">
        <Stack align="center">
          <SignInWithGoogleButton text="signup_with" />
          <SignInWithAppleButton text="sign-up" />
          {LINKEDIN_ENABLED && (
            <SignInWithLinkedInButton text="signUpWithLinkedIn" />
          )}
        </Stack>

        <Divider
          my="lg"
          color="gray.6"
          labelPosition="center"
          label={<Text size="md">{t('login.or')}</Text>}
        />

        <SignupForm />

        <Text size="sm" mt="md" ta="center">
          <Trans i18nKey="signup.agreement">
            By signing up, you agree to our{' '}
            <Anchor component={Link} to={AppRouteURL.terms}>
              Terms of Service
            </Anchor>{' '}
            and{' '}
            <Anchor component={Link} to={AppRouteURL.privacyPolicy}>
              Privacy Policy
            </Anchor>
            .
          </Trans>
        </Text>
      </Stack>
    </LoginSignupPage>
  );
};
