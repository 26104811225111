import { Center, Container, Title } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Bowl } from '../../components/Bowl/Bowl';
import { Logo } from '../../components/Logo/Logo';
import { ResetPasswordForm } from '../../components/ResetPasswordForm/ResetPasswordForm';

export const ResetPasswordPage: React.FC = () => {
  const [params] = useSearchParams();

  const token = params.get('token');
  const id = params.get('id');

  const { t } = useTranslation();

  return (
    <Container size="sm">
      <Bowl title={t('resetPassword.pageTitle')} />
      <Center my="lg">
        <Logo type="Banner" />
      </Center>

      {token && id ? (
        <ResetPasswordForm token={token} id={id} />
      ) : (
        <Title order={2}>{t('resetPassword.invalidToken')}</Title>
      )}
    </Container>
  );
};
