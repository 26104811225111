import { Paper, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { useProfileCompleteness } from '../../models/ProfileQueries';

import classes from './HomeCard.module.css';

const ProfileStatusCard: React.FC = () => {
  const { t } = useTranslation();
  const profileCompleteness = useProfileCompleteness();
  const navigate = useNavigate();

  return (
    <Paper
      shadow="lg"
      radius="xl"
      p="xl"
      onClick={() => {
        navigate(AppRouteURL.profileTabBasic);
      }}
      className={classes.homeCard}
    >
      <div className={classes.homeCardWrapper}>
        <Title order={5}>{t('header.profile')}</Title>
        <div className={classes.homeCardContent}>
          <Stack gap="xl" align="center" justify="center" h="100%">
            <Text size="4rem">{profileCompleteness}%</Text>
            <Text size="2rem">{t('home.complete')}</Text>
          </Stack>
        </div>
      </div>
    </Paper>
  );
};

export default ProfileStatusCard;
