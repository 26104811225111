import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { getLogger } from '../services/Logger';
import { AppleTransaction } from '../services/MessageHandlers';
import { queryClient } from '../services/QueryClient';
import { IUser, QUERY_KEY_USER } from './User';

const logger = getLogger('Subscription');

export const postAppleTransaction = async function (
  transaction: AppleTransaction,
) {
  const user = await Api.ky
    .post(ApiRoutes.TransactionsApple, { json: transaction })
    .json<IUser>();

  queryClient.setQueryData(QUERY_KEY_USER, user);

  return user;
};
