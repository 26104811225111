import {
  Button,
  MantineSize,
  Menu,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCaretDown } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../i18n';
import { useUpdateUserMutation } from '../../queries/UserQueries';
import { getLogger } from '../../services/Logger';

const log = getLogger('LanguageMenu');

export const LanguageMenu: React.FC<{
  long?: boolean;
  size?: MantineSize;
  minViewPortSize?: MantineSize;
}> = ({ long, size = 'lg', minViewPortSize }) => {
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const isOverMinSize =
    minViewPortSize === undefined ||
    useMediaQuery(`(min-width: ${theme.breakpoints[minViewPortSize]})`);
  const updateMutation = useUpdateUserMutation();

  const languages = [
    { value: 'en', label: t('languages.en'), short: '🇬🇧' },
    { value: 'es', label: t('languages.es'), short: '🇪🇸' },
    { value: 'fr', label: t('languages.fr'), short: '🇫🇷' },
    { value: 'de', label: t('languages.de'), short: '🇩🇪' },
    { value: 'it', label: t('languages.it'), short: '🇮🇹' },
    { value: 'pt', label: t('languages.pt'), short: '🇵🇹' },
  ] as { value: string; label: string; short: string }[];

  const [selectedLanguage, setSelectedLanguage] = React.useState<
    (typeof languages)[number]
  >(
    languages.find((entry) => i18n.language.startsWith(entry.value)) ||
      languages[0],
  );

  const updateLanguageSetting = async (language: string) => {
    try {
      setSelectedLanguage(
        languages.find((entry) => entry.value === language) || languages[0],
      );
      await changeLanguage(language);
      // TODO cannot save for unauthorized users
      await updateMutation.mutateAsync({ language });
    } catch (error) {
      log.error('Error while updating language:', error);
    }
  };

  return (
    isOverMinSize && (
      <Menu
        shadow="md"
        width={200}
        transitionProps={{ transition: 'pop-top-right' }}
        position="bottom-end"
        withArrow
      >
        <Menu.Target>
          <Button
            variant="subtle"
            p={4}
            rightSection={<IconCaretDown size={16} color="grey" />}
          >
            <Text size={size}>
              {long ? selectedLanguage.label : selectedLanguage.short}
            </Text>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {languages.map((language) => (
            <Menu.Item
              key={language.value}
              onClick={() => updateLanguageSetting(language.value)}
            >
              {language.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    )
  );
};
