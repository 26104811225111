import { useEffect } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { getLogger } from '../../services/Logger';
import Platform, {
  APNSTokenReceivedEvent,
  PushNotificationEvent,
} from '../../services/Platform';

const log = getLogger('PlatformBridge');

function sendApnsToken(apnsToken: string) {
  return Api.ky.post(ApiRoutes.ApnsTokens, {
    json: { token: apnsToken },
  });
}

function handleAPNSToken(event: Event) {
  const customEvent = event as APNSTokenReceivedEvent;
  const apnsToken = customEvent.detail;
  log.info(`Received APNS token: ${apnsToken}`);
  sendApnsToken(apnsToken).catch((error) => {
    log.error(`Failed to send APNS token: ${error}`);
  });
}

function handlePushNotification(event: Event, navigate: NavigateFunction) {
  const { userInfo, type } = (event as PushNotificationEvent).detail;
  log.info(`Received push notification: ${JSON.stringify(userInfo)}`);

  if (!userInfo.resumeId) {
    log.warn('Push notification without resumeId, ignoring');
    return;
  }

  if (typeof userInfo.resumeId === 'string' && userInfo.resumeId.length > 0) {
    if (type === 'tapped') {
      log.info('Navigating to resume view for resumeId ' + userInfo.resumeId);
      navigate(AppRouteURL.resumes.view + '/' + userInfo.resumeId);
    } else {
      log.info('Push notification without tap event, ignoring');
    }
  } else {
    log.warn('Invalid resumeId in push notification');
  }
}

export const PlatformBridge: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Platform.sendImpactEvent();
  }, [location.pathname]);

  useEffect(() => {
    const pushHandler = (event: Event) => {
      handlePushNotification(event, navigate);
    };

    window.addEventListener('apnsTokenReceived', handleAPNSToken);
    window.addEventListener('pushNotification', pushHandler);

    Platform.setPlatformVariable('userId', user?.id || null);
    if (user?.id) {
      Platform.requestPushPermission();
    }

    // Cleanup function
    return () => {
      window.removeEventListener('apnsTokenReceived', handleAPNSToken);
      window.removeEventListener('pushNotification', pushHandler);
    };
  }, [user?.id]);

  return null;
};
