import {
  AppShell,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Bowl } from '../../components/Bowl/Bowl';
import { LoginFooter } from '../../components/LoginFooter/LoginFooter';
import { Logo } from '../../components/Logo/Logo';

import classes from './LoginSignupPage.module.css';

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 150,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: easing,
    },
  },
};

interface LoginSignupPageProps {
  title: string;
  children: ReactNode;
  footerText: string;
  footerLink: string;
  footerLinkText: string;
}

export const LoginSignupPage: React.FC<LoginSignupPageProps> = ({
  title,
  children,
  footerText,
  footerLink,
  footerLinkText,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <AppShell footer={{ height: '2.5rem' }}>
      <Bowl title={title}>
        <link rel="preconnect" href="https://appleid.cdn-apple.com" />
        <link rel="preconnect" href="https://accounts.google.com" />
      </Bowl>

      <AppShell.Main
        display="flex"
        style={{ justifyContent: 'center', alignItems: 'center' }}
        w="100%"
      >
        <div className={classes.fullscreenBackground} />

        <Center h="100%" w="100%">
          <Container size="xl" h="100%" w="100%">
            <Grid
              gutter="md"
              justify="center"
              align="center"
              w="100%"
              h="100%"
              m={0}
              styles={{ inner: { height: '100%' } }}
            >
              <Grid.Col span={{ base: 12, md: 6 }} p={0}>
                <Container size="xs" mb="xl">
                  <Stack>
                    {isMobile && (
                      <Center m="sm">
                        <Logo type="Banner" h={72} />
                      </Center>
                    )}
                    <Title
                      order={1}
                      fw={500}
                      fz={isMobile ? 26 : 38}
                      ta="center"
                    >
                      {title}
                    </Title>

                    {children}

                    <Divider
                      color="gray.6"
                      labelPosition="center"
                      label={<Text size="md">{footerText}</Text>}
                    />
                    <Button
                      component={Link}
                      to={footerLink}
                      variant="light"
                      fullWidth
                      size="md"
                    >
                      {footerLinkText}
                    </Button>
                  </Stack>
                </Container>
              </Grid.Col>
              {!isMobile && (
                <Grid.Col span={6} h="100%" p={0}>
                  <motion.div style={{ height: '100%' }} {...fadeInUp}>
                    <Flex align="center" justify="center" h="100%" p={0}>
                      <Stack align="center" gap={96}>
                        <Center component={motion.div} {...fadeInUp}>
                          <Logo type="Banner" fit="cover" h={148} />
                        </Center>
                      </Stack>
                    </Flex>
                  </motion.div>
                </Grid.Col>
              )}
            </Grid>
          </Container>
        </Center>
      </AppShell.Main>

      <LoginFooter />
    </AppShell>
  );
};
