import { IUser } from '../models/User';
import getAnonRoutes from './AnonRoutes';
import getAuthenticatedRoutes from './AuthenticatedRoutes';

const getRoutes = (loading: boolean, user?: IUser | null) =>
  Array.from([
    ...getAnonRoutes(loading, user),
    ...getAuthenticatedRoutes(loading, user),
  ]).map((item) => ({ ...item, id: undefined }));

export default getRoutes;
