import { lazy } from 'react';
import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { AppRouteURL } from '../AppRouteURL';
import { Layout } from '../components/Layout/Layout';
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen';
import { IUser, RoleType } from '../models/User';
import UserAdminPage from '../pages/Admin/UserAdminPage/UserAdminPage';
import { AnalyzeResumePage } from '../pages/AnalyzeResumePage/AnalyzeResumePage';
import { CheckoutCanceledPage } from '../pages/CheckoutCanceledPage/CheckoutCanceledPage';
import { CheckoutSuccessPage } from '../pages/CheckoutSuccessPage/CheckoutSuccessPage';
import EditAccountPage from '../pages/EditAccountPage/EditAccountPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import FeedbackPage from '../pages/FeedbackPage/FeedbackPage';
import Home from '../pages/Home/Home';
import { ImportProfilePage } from '../pages/ImportProfilePage/ImportProfilePage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import { getResumeRoutes } from './ResumeRoutes';

const AdminPage = lazy(
  () => import(/* webpackChunkName: "admin" */ '../pages/Admin/AdminPage'),
);

const getAuthenticatedRoutes = (loading: boolean, user?: IUser | null) =>
  createRoutesFromElements(
    <>
      <Route
        path={AppRouteURL.home}
        element={
          user ? (
            <Layout />
          ) : loading ? (
            <LoadingScreen />
          ) : (
            <Navigate to={AppRouteURL.login} replace />
          )
        }
        errorElement={<ErrorPage />}
      >
        <Route index element={<Home />} />
        <Route path={AppRouteURL.feedback} element={<FeedbackPage />} />

        {/* Account */}
        <Route
          path={`${AppRouteURL.profile}/:tab?`}
          element={<ProfilePage />}
        />
        <Route
          path={AppRouteURL.profileImport}
          element={<ImportProfilePage />}
        />

        <Route path={AppRouteURL.account} element={<EditAccountPage />} />

        {/* Resume */}
        {getResumeRoutes()}

        {/* Analyze */}
        <Route path={AppRouteURL.analyze} element={<AnalyzeResumePage />} />

        {/* Checkout */}
        <Route
          path={AppRouteURL.checkoutSuccess}
          element={<CheckoutSuccessPage />}
        />
        <Route
          path={AppRouteURL.checkoutCanceled}
          element={<CheckoutCanceledPage />}
        />

        {/* Admin */}
        <Route
          path={AppRouteURL.admin}
          element={user?.role !== RoleType.ADMIN && <Navigate to="/" replace />}
        >
          <Route index element={<AdminPage />} />
          <Route path={AppRouteURL.adminUser} element={<UserAdminPage />} />
        </Route>
      </Route>
    </>,
  );

export default getAuthenticatedRoutes;
