import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { AppRouteURL } from '../AppRouteURL';
import { EditResumePage } from '../pages/EditResumePage/EditResumePage';
import { ListResumesPage } from '../pages/ListResumesPage/ListResumesPage';
import { ResumeWizardPage } from '../pages/ResumeWizardPage/ResumeWizardPage';

const ViewResumePage = lazy(
  () =>
    import(
      /* webpackChunkName: "view-resume" */ '../pages/ViewResumePage/ViewResumePage'
    ),
);

export const getResumeRoutes = () => (
  <Route path={AppRouteURL.resumes.index}>
    <Route path="list" element={<ListResumesPage />} />

    <Route path="create/:step?" element={<ResumeWizardPage />} />

    <Route
      path={`view/:resumeId?/:tab?/:interviewId?/:action?`}
      element={<ViewResumePage />}
    />

    <Route path={`edit/:resumeId`} element={<EditResumePage />} />
  </Route>
);
