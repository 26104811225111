import { Box, Menu } from '@mantine/core';
import React from 'react';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { UserMenuDropdown } from './UserMenuDropdown';

export const UserMenu: React.FC = () => (
  <Menu
    trigger="click-hover"
    shadow="md"
    width={200}
    transitionProps={{ transition: 'pop-top-right' }}
    position="bottom-end"
    withArrow
  >
    <Menu.Target>
      <Box>
        <UserAvatar />
      </Box>
    </Menu.Target>

    <UserMenuDropdown />
  </Menu>
);
