import {
  Alert,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification, updateNotification } from '@mantine/notifications';
import {
  IconBulb,
  IconDeviceFloppy,
  IconEye,
  IconStar,
  IconX,
} from '@tabler/icons-react';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AppRouteURL } from '../../AppRouteURL';
import { PRODUCT_NAME } from '../../Constants';
import { IResume } from '../../models/Resume';
import {
  useResumeQuery,
  useSuggestionMutation,
  useUpdateResumeMutation,
} from '../../queries/ResumeQueries';

type ResumeValues = Partial<IResume>;

/* eslint-disable i18next/no-literal-string */

export const EditResumeForm: React.FC<{ resumeId: IResume['id'] }> = ({
  resumeId,
}) => {
  const updateMutation = useUpdateResumeMutation(resumeId);
  const suggestionMutation = useSuggestionMutation(resumeId);
  const { data: resume } = useResumeQuery(resumeId);

  const createSuggestion = async () => {
    try {
      showNotification({
        id: 'suggestion',
        title: 'Creating suggestion...',
        message:
          'We are creating a suggestion for your resume. This might take up to several minutes.',
        loading: true,
        autoClose: false,
      });
      await suggestionMutation.mutateAsync();
      updateNotification({
        id: 'suggestion',
        title: 'Created suggestion',
        message: 'Your suggestion has been successfully created.',
        autoClose: 5000,
        loading: false,
      });
    } catch (error) {
      updateNotification({
        id: 'suggestion',
        title: 'Error while creating suggestion',
        message:
          'We encountered an error while creating a new suggestion for your resume. ' +
          'Please try again in a few minutes. You might also try to edit your input data slightly.',
        color: 'red',
        icon: <IconX />,
        loading: false,
      });
      console.error(error);
    }
  };

  const onNewSuggestion = () => {
    openConfirmModal({
      title: 'Create new suggestion',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to regenerate your resume suggestion? The
          previous CV will no longer be available.
        </Text>
      ),
      labels: { confirm: 'Create suggestion', cancel: 'Cancel' },
      onConfirm: createSuggestion,
    });
  };

  const EditResumeSchema = Yup.object().shape({
    title: Yup.string().required('Provide a title'),
    info: Yup.string().required('Provide an intro text'),
  });

  const initialValues: ResumeValues = {
    title: resume?.title ?? '',
    info: resume?.info?.trim() ?? '',
  };

  const onSubmit: (
    values: ResumeValues,
    formikHelpers: FormikHelpers<ResumeValues>,
  ) => Promise<void> = async ({ title, info }, actions) => {
    try {
      await updateMutation.mutateAsync({
        title,
        info,
      });
    } catch (error) {
      console.error(error);
      actions.setStatus(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditResumeSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        errors,
        dirty,
        touched,
        isValid,
        isSubmitting,
        handleSubmit,
        getFieldProps,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Group justify="space-between">
            <Group gap={2}>
              <Button
                type="submit"
                variant="outline"
                loading={isSubmitting}
                leftSection={<IconDeviceFloppy />}
                disabled={
                  !(
                    dirty &&
                    !isSubmitting &&
                    isValid &&
                    !suggestionMutation.isPending &&
                    resumeId
                  )
                }
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
              <Button
                component={Link}
                to={`${AppRouteURL.resumes.view}/${resumeId}`}
                variant="outline"
                disabled={!resume?.suggestion}
                leftSection={<IconEye />}
              >
                View
              </Button>
            </Group>
            <Button
              variant="outline"
              loading={suggestionMutation.isPending}
              leftSection={<IconStar />}
              onClick={onNewSuggestion}
              disabled={dirty || isSubmitting || !isValid || !resumeId}
            >
              {suggestionMutation.isPending
                ? 'Suggesting...'
                : 'Create new suggestion'}
            </Button>
          </Group>

          <Divider my="xl" />

          <Stack gap="xl">
            <Paper p="md">
              <Stack>
                <TextInput
                  label="Title"
                  placeholder="Enter a title for this resume"
                  {...getFieldProps('title')}
                  error={touched.title && errors.title}
                />

                <Stack gap="sm">
                  <Textarea
                    autosize
                    minRows={3}
                    placeholder="Enter some info on the purpose of this resume or paste a job ad"
                    label="Info"
                    {...getFieldProps('info')}
                    error={touched.info && errors.info}
                  />

                  <Alert icon={<IconBulb />} title="What is this?">
                    <Text>
                      Here you can enter additional information on the target
                      audience for this CV. {PRODUCT_NAME} will tailor your CV
                      to these instructions. Try pasting the job ad you are
                      applying to!
                    </Text>
                  </Alert>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
