import { Anchor, Button, PasswordInput, Stack, TextInput } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';

import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Api from '../../Api';
import { AppRouteURL } from '../../AppRouteURL';
import { useAuth } from '../../Auth';
import { getLogger } from '../../services/Logger';
import Tracking from '../../services/Tracking';

const logger = getLogger('LoginForm');

interface ILocationState {
  from: string;
}

export const LoginForm: React.FC = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as ILocationState | undefined)?.from;
  const navigationTarget =
    from && from !== AppRouteURL.login ? from : AppRouteURL.home;
  const { t } = useTranslation();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('login.emailError'))
      .required(t('login.emailRequired')),
    password: Yup.string().required(t('login.passwordRequired')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ email, password }, actions) => {
      try {
        await signin({ email, password });

        Tracking.trackLogin({ method: 'email' });

        navigate(navigationTarget, { replace: true });
      } catch (error) {
        const errorMessage = await Api.getErrorMessage(error);
        actions.setErrors({
          email: errorMessage,
          password: errorMessage,
        });
        actions.setStatus(error);
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack>
          <Stack>
            <TextInput
              placeholder={t('login.emailPlaceholder')}
              label={t('login.emailLabel')}
              type="email"
              size="md"
              {...getFieldProps('email')}
              error={touched.email && errors.email ? errors.email : null}
            />
            <PasswordInput
              placeholder={t('login.passwordPlaceholder')}
              label={t('login.passwordLabel')}
              size="md"
              {...getFieldProps('password')}
              error={
                touched.password && errors.password ? errors.password : null
              }
            />
          </Stack>

          <Anchor
            mb="lg"
            mx="auto"
            component={RouterLink}
            fz="md"
            to={AppRouteURL.forgotPassword}
          >
            {t('login.forgotPassword')}
          </Anchor>
        </Stack>

        <Button
          fullWidth
          size="lg"
          type="submit"
          variant="filled"
          loading={isSubmitting}
          leftSection={<IconUser />}
        >
          {isSubmitting ? t('labels.loading') : t('login.submitButton')}
        </Button>
      </Form>
    </FormikProvider>
  );
};
