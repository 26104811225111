import {
  Center,
  Container,
  Grid,
  Stack,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bowl } from '../../components/Bowl/Bowl';
import { CreateResumeButton } from '../../components/CreateResumeButton/CreateResumeButton';
import { useResumesQuery } from '../../queries/ResumeQueries';
import AnalyzerCard from './AnalyzerCard';
import ApplicationStatusCard from './ApplicationStatusCard';
import OnboardingModal from './OnboardingModal';
import ProfileStatusCard from './ProfileStatusCard';
import RecommendationCard from './RecommendationCard';
import SubscribeCard from './SubscribeCard';

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 100,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};
const fadeInDown = {
  initial: {
    y: -100,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};
const fadeInLeft = {
  initial: {
    x: -200,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};
const fadeInRight = {
  initial: {
    x: 200,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { data: resumes } = useResumesQuery();
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Container>
      <Bowl title={t('home.pageTitle')} />
      <Stack gap="xl">
        <Center>
          {/*<Logo h={160} type="Banner" />*/}
          <Title order={1}>{t('welcomeHuman')}</Title>
        </Center>

        {smallScreen && (
          <Center my="lg">
            <CreateResumeButton />
          </Center>
        )}

        <Grid gutter={{ base: 'md', sm: 'xs' }}>
          {Array.isArray(resumes) && resumes.length === 0 && (
            <Grid.Col span={12}>
              <motion.div {...fadeInDown}>
                <RecommendationCard />
              </motion.div>
            </Grid.Col>
          )}
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <motion.div {...fadeInLeft}>
              <ProfileStatusCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <motion.div {...fadeInRight}>
              <ApplicationStatusCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <motion.div {...fadeInUp}>
              <SubscribeCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>
            <motion.div {...fadeInUp}>
              <AnalyzerCard />
            </motion.div>
          </Grid.Col>
        </Grid>
      </Stack>
      <OnboardingModal />
    </Container>
  );
};

export default Home;
