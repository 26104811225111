import { TextInput, ThemeIcon, Tooltip } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Field, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProfileInputProps<T> {
  name: keyof T | string;
  icon: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  formikProps: FormikProps<T>;
  label?: string;
  placeholder?: string;
}

export const ProfileInput = <T extends object>({
  name,
  type = 'text',
  icon,
  label,
  placeholder,
  formikProps: { touched, errors, values, initialValues, getFieldProps },
}: ProfileInputProps<T>) => {
  const { t } = useTranslation();
  const key = name as keyof T;
  return (
    <Field
      as={TextInput}
      autoComplete="off"
      type={type}
      label={label ?? t(`contact.${String(name)}`)}
      leftSection={icon}
      placeholder={placeholder ?? t(`contact.${String(name)}Placeholder`)}
      {...getFieldProps(String(name))}
      error={touched[key] && (errors[key] as string)}
      rightSection={
        values[key] !== initialValues[key] && (
          <Tooltip label={t('profile.unsavedChangesTooltip')}>
            <ThemeIcon color="yellow" variant="light">
              <IconAlertTriangle />
            </ThemeIcon>
          </Tooltip>
        )
      }
    />
  );
};
