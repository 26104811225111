import { useTranslation } from 'react-i18next';
import { IResume, ResumeStatus } from '../../models/Resume';

export const ResumeTitleText: React.FC<{ resume: IResume }> = ({ resume }) => {
  const { t } = useTranslation();

  return resume.status === ResumeStatus.Ok
    ? resume.title
    : '(' + (t(`application.status.${resume.status}`) as string) + ')';
};
