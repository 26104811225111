import { ILogObj, IMeta } from 'tslog';
import { IProduct } from '../models/Product';
import { postAppleTransaction } from '../models/Subscription';
import { IUser } from '../models/User';
import { attachTransport, getLogger } from './Logger';
import { AppleTransaction } from './MessageHandlers';
import Tracking from './Tracking';

const log = getLogger('Platform');

export type PlatformName = 'web' | 'ios-pwa' | 'android-pwa';

const APPLE_PRODUCT_IDS = ['com.resufit.sub.weekly', 'com.resufit.sub.monthly'];

const FALLBACK_PRODUCTS = [
  {
    id: 'com.resufit.sub.monthly',
    recurring: {
      interval: 'month' as const,
      trial_period_days: 3,
      interval_count: 1,
    },
    nickname: 'ResuFit Pro Monthly',
    currencies: { eur: { unitAmount: 999 } },
  },
  {
    nickname: 'ResuFit Pro Weekly',
    currencies: { eur: { unitAmount: 399 } },
    id: 'com.resufit.sub.weekly',
    recurring: {
      interval: 'week' as const,
      trial_period_days: 3,
      interval_count: 1,
    },
  },
];

export interface PlatformConfig {
  platform?: PlatformName;
  lang?: string;
}

export type UpdateTransactionEvent = CustomEvent<{ transaction: string }>;
export type AttStatusChangeEvent = CustomEvent<{ status: string }>;
export type PushPermissionStatusEvent = CustomEvent<string>;
export type APNSTokenReceivedEvent = CustomEvent<string>;
export type APNSTokenFailedEvent = CustomEvent<string>;
export type PushNotificationEvent = CustomEvent<{
  userInfo: Record<string, unknown>;
  type: 'tapped' | 'received';
}>;

export class PlatformHandler {
  public products?: IProduct[];

  constructor() {
    window.platform = this;

    this.initPlatformName();

    if (this.isPwa) {
      attachTransport((logObj) => {
        this.sendLogObj(logObj);
      });

      this.initializeTransactionHandler();
      this.initializeATTHandler();
      this.initializeAPNHandler();
    }

    this.loadProducts().catch((error) => {
      log.error(`Error while loading products: ${(error as Error).message}`);
    });
  }

  private initPlatformName() {
    const platformLocalStorageValue = localStorage.getItem('platform-name');

    log.info(
      'Platform localStorage value: ' + (platformLocalStorageValue || ''),
    );

    if (platformLocalStorageValue === 'ios-pwa') {
      window.resufit = { ...window.resufit, platform: 'ios-pwa' };
    }
  }

  get platformName(): PlatformName {
    return window.resufit?.platform || 'web';
  }

  get iosPwa(): boolean {
    return this.platformName === 'ios-pwa';
  }

  get androidPwa(): boolean {
    return this.platformName === 'android-pwa';
  }

  get isPwa(): boolean {
    return this.iosPwa || this.androidPwa;
  }

  get lang(): string {
    return window.resufit?.lang || 'en';
  }

  async openSubscriptionManagement() {
    if (this.isPwa) {
      await window.webkit?.messageHandlers?.openSubscriptionManagement?.postMessage(
        {},
      );
    }
  }

  async startPurchase(userId: IUser['id'], productId: string): Promise<void> {
    if (!this.isPwa) {
      throw new Error('Cannot start purchase on web');
    }

    log.info(`Starting purchase for product: ${productId} - User: ${userId}`);
    try {
      await window.webkit?.messageHandlers?.startPurchase?.postMessage({
        userId,
        productId,
      });
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : 'unknown');
    }
  }

  async loadProducts() {
    if (Array.isArray(this.products) && this.products.length > 0) {
      return this.products;
    }

    const productsJson =
      await window.webkit?.messageHandlers?.loadProducts?.postMessage(
        APPLE_PRODUCT_IDS,
      );
    if (!productsJson) {
      log.info('Could not load products from Apple, falling back to default');
      this.products = FALLBACK_PRODUCTS;
      return;
    }

    const products = JSON.parse(productsJson) as IProduct[];
    if (!Array.isArray(products) || products.length === 0) {
      log.error('Invalid products received from Apple');
      this.products = FALLBACK_PRODUCTS;
      return;
    }

    log.info('Loaded products: ' + JSON.stringify(products));
    this.products = products;
  }

  getProducts() {
    if (!this.products || this.products.length === 0) {
      log.warn('Trying to access Apple products before being loaded');
    }
    return this.products;
  }

  sendLogMessage(msg: string, ctx: string, level: string) {
    window.webkit?.messageHandlers?.logMessage?.postMessage({
      msg,
      ctx,
      level,
    });
  }

  sendLogObj(logObj: ILogObj) {
    const meta = logObj._meta as IMeta;
    this.sendLogMessage(
      JSON.stringify(logObj['0']),
      meta.name ?? 'none',
      meta.logLevelName ?? 'INFO',
    );
  }

  initializeTransactionHandler() {
    window.addEventListener('updateTransaction', (event: Event) => {
      const customEvent = event as UpdateTransactionEvent;
      void this.updateTransaction(customEvent.detail.transaction);
    });
  }

  async updateTransaction(jsonData: string) {
    const transaction = JSON.parse(jsonData) as AppleTransaction;
    await postAppleTransaction(transaction);
    log.info(
      `Transaction Update sent to backend: ${JSON.stringify(transaction)}`,
    );
  }

  initializeATTHandler() {
    const handleATTStatus = (attStatus: string) => {
      if (attStatus === 'authorized') {
        Tracking.consentAll();
      } else {
        Tracking.denyAll();
      }
    };

    if (window.attStatus) {
      log.info(`Initial ATT status available: ${window.attStatus}`);
      handleATTStatus(window.attStatus);
    } else {
      log.info('No initial ATT status set');
    }

    window.addEventListener('attStatusChange', (event: Event) => {
      const customEvent = event as AttStatusChangeEvent;
      const status = customEvent.detail.status;
      log.info(`ATT status changed: ${status}`);
      handleATTStatus(status);
    });
  }

  setColorScheme(colorScheme: string) {
    window.webkit?.messageHandlers?.setColorScheme
      ?.postMessage(colorScheme)
      .catch((error) => {
        log.error(`Failed to set color scheme: ${error}`);
      });
  }

  setPlatformVariable(key: string, value: string | null) {
    log.info(`Setting platform variable: ${key} = ${value}`);
    window.webkit?.messageHandlers?.setPlatformVariable
      ?.postMessage({ key, value })
      .catch((error) => {
        log.error(`Failed to set platform variable: ${error}`);
      });
  }

  initializeAPNHandler() {
    window.addEventListener('pushPermissionStatus', (event: Event) => {
      const customEvent = event as PushPermissionStatusEvent;
      const status = customEvent.detail;
      log.info(`Received Push status: ${status}`);
    });

    window.addEventListener('apnsTokenReceived', (event: Event) => {
      const customEvent = event as APNSTokenReceivedEvent;
      const apnsToken = customEvent.detail;
      log.info(`Received APNS token: ${apnsToken}`);
    });

    window.addEventListener('apnsTokenFailed', (event: Event) => {
      const customEvent = event as APNSTokenFailedEvent;
      const error = customEvent.detail;
      log.warn(`Failed to received APNS token, error: ${error}`);
    });
  }

  requestPushPermission() {
    log.info('Requesting push permission');
    window.webkit?.messageHandlers?.requestPushPermission
      ?.postMessage({})
      .catch((error) => {
        log.error(`Failed to request push permission: ${error}`);
      });
  }

  sendImpactEvent() {
    window.webkit?.messageHandlers?.generateImpact?.postMessage({});
  }
}

export default new PlatformHandler();
