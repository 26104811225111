import { Anchor, Center, Container, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';

export const CheckoutCanceledPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container size="xs">
      <Stack gap="xl">
        <Bowl title={t('translation:checkout.canceledPageTitle')} />

        <Center>
          <Title order={2}>{t('translation:checkout.canceledPageTitle')}</Title>
        </Center>

        <Center>
          <Text fz="xl">{t('checkout.canceledDescription')}</Text>
        </Center>
        <Anchor component={Link} to={AppRouteURL.subscribe}>
          {t('checkout.canceledGoBack')}
        </Anchor>
      </Stack>
    </Container>
  );
};
