import { IconHome, IconMail, IconPhone, IconUser } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IUserProfile } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { ProfileInput } from './ProfileInput';
import { ProfileModal } from './ProfileModal';

type BasicProfileValues = Pick<
  IUserProfile,
  'firstName' | 'lastName' | 'phone' | 'address' | 'displayEmail'
>;

const BasicInfoSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  phone: Yup.string().optional(),
  address: Yup.string().optional(),
  displayEmail: Yup.string().email().optional(),
});

export const BasicInfoModal: React.FC<{
  opened: boolean;
  onClose: () => void;
}> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const { data: profile } = useProfileQuery();

  const initialValues = {
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
    phone: profile?.phone ?? '',
    address: profile?.address ?? '',
    displayEmail: profile?.displayEmail ?? '',
  } as BasicProfileValues;

  return (
    <ProfileModal<BasicProfileValues>
      opened={opened}
      onClose={onClose}
      title={t('profile.basicInfoTitle')}
      initialValues={initialValues}
      validationSchema={BasicInfoSchema}
    >
      {(formikProps) => (
        <>
          <ProfileInput
            name="firstName"
            icon={<IconUser />}
            formikProps={formikProps}
          />
          <ProfileInput
            name="lastName"
            icon={<IconUser />}
            formikProps={formikProps}
          />
          <ProfileInput
            name="phone"
            icon={<IconPhone />}
            type="tel"
            formikProps={formikProps}
          />
          <ProfileInput
            name="address"
            icon={<IconHome />}
            formikProps={formikProps}
          />
          <ProfileInput
            name="displayEmail"
            icon={<IconMail />}
            type="email"
            formikProps={formikProps}
          />
        </>
      )}
    </ProfileModal>
  );
};
